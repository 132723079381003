const CONFIG_PER_ENVIRONMENT: any = {
    mock: {
        requestHost: 'mock',
        // requestHost: 'https://ecm0x9z4eg.execute-api.us-east-1.amazonaws.com',
        firebaseConfig: {
            apiKey: "AIzaSyAI-MPFkYtQ0Xrt95g6QrmFqZUXJV5-NnU",
            authDomain: "telehealth-f95c8.firebaseapp.com",
            projectId: "telehealth-f95c8",
            storageBucket: "telehealth-f95c8.appspot.com",
            messagingSenderId: "279246846810",
            appId: "1:279246846810:web:6900a2b79ee7a30c3ed163",
            measurementId: "G-NZF4SDZKF4"
        },
        datadogClientToken: "pubdaea549f7513bca34f5bb125ff432f1e",
        environment: 'local',
        portalApiBaseUrl: 'https://v3devservice.myctca.com',
        auth0: {
            clientId: "2bvvjmHgUmnr4zRGlensoIVx1bp7FOFM",
            redirectUri: "http://localhost:4200/callback",
            domain: "ctca-dev.us.auth0.com",
            audience: "http://localportalservice.ctca.local/api"
        },
    },
    dev: {
        requestHost: 'https://7laa6389ma.execute-api.us-east-1.amazonaws.com',
        // requestHost: 'https://ecm0x9z4eg.execute-api.us-east-1.amazonaws.com',
        // requestHost: 'https://0sbdp4zcz8.execute-api.us-east-2.amazonaws.com',
        firebaseConfig: {
            apiKey: "AIzaSyAI-MPFkYtQ0Xrt95g6QrmFqZUXJV5-NnU",
            authDomain: "telehealth-f95c8.firebaseapp.com",
            projectId: "telehealth-f95c8",
            storageBucket: "telehealth-f95c8.appspot.com",
            messagingSenderId: "279246846810",
            appId: "1:279246846810:web:6900a2b79ee7a30c3ed163",
            measurementId: "G-NZF4SDZKF4"
        },
        datadogClientToken: "pubdaea549f7513bca34f5bb125ff432f1e",
        environment: 'dev',
        portalApiBaseUrl: 'https://v3devservice.myctca.com',
        auth0: {
            clientId: "2bvvjmHgUmnr4zRGlensoIVx1bp7FOFM",
            redirectUri: "https://dev.telehealth.myctca.com/callback",
            domain: "ctca-dev.us.auth0.com",
            audience: "http://v3devservice.myctca.com/api"
        },
    },
    qa: {
        requestHost: 'https://3kac27nnol.execute-api.us-east-1.amazonaws.com',
        firebaseConfig: {
            apiKey: "AIzaSyAI-MPFkYtQ0Xrt95g6QrmFqZUXJV5-NnU",
            authDomain: "telehealth-f95c8.firebaseapp.com",
            projectId: "telehealth-f95c8",
            storageBucket: "telehealth-f95c8.appspot.com",
            messagingSenderId: "279246846810",
            appId: "1:279246846810:web:5ff54d704049d2e73ed163",
            measurementId: "G-JKHDYKRTVE",
        },
        datadogClientToken: "pubdaea549f7513bca34f5bb125ff432f1e",
        environment: 'qa',
        portalApiBaseUrl: 'https://v3qaservice.myctca.com',
        auth0: {
            clientId: "GJ8g5QOv2ZLpTxSdg9Jb59Y5VWhgIEeH",
            redirectUri: "https://qa.telehealth.myctca.com/callback",
            domain: "ctca-test.us.auth0.com",
            audience: "http://portalservice.ctca.test/api"
        },
    },
    stage: {
        requestHost: 'https://3kac27nnol.execute-api.us-east-1.amazonaws.com',
        firebaseConfig: {
            apiKey: "AIzaSyAI-MPFkYtQ0Xrt95g6QrmFqZUXJV5-NnU",
            authDomain: "telehealth-f95c8.firebaseapp.com",
            projectId: "telehealth-f95c8",
            storageBucket: "telehealth-f95c8.appspot.com",
            messagingSenderId: "279246846810",
            appId: "1:279246846810:web:5ff54d704049d2e73ed163",
            measurementId: "G-JKHDYKRTVE",
        },
        datadogClientToken: "pubdaea549f7513bca34f5bb125ff432f1e",
        environment: 'stage',
        portalApiBaseUrl: 'https://v3qaservice.myctca.com',
        auth0: {
            clientId: "GJ8g5QOv2ZLpTxSdg9Jb59Y5VWhgIEeH",
            redirectUri: "https://stage.telehealth.myctca.com/callback",
            domain: "ctca-test.us.auth0.com",
            audience: "http://portalservice.ctca.test/api"
        },
    },
    prod: {
        requestHost: 'https://kmjl55zhud.execute-api.us-east-1.amazonaws.com',
        firebaseConfig: {
            apiKey: "AIzaSyAI-MPFkYtQ0Xrt95g6QrmFqZUXJV5-NnU",
            authDomain: "telehealth-f95c8.firebaseapp.com",
            projectId: "telehealth-f95c8",
            storageBucket: "telehealth-f95c8.appspot.com",
            messagingSenderId: "279246846810",
            appId: "1:279246846810:web:35443f0563192c693ed163",
            measurementId: "G-FD0Z0X7QP9"
        },
        datadogClientToken: "pubdaea549f7513bca34f5bb125ff432f1e",
        environment: 'prod',
        portalApiBaseUrl: 'https://service.myctca.com',
        auth0: {
            clientId: "jFbRXAZCxo3Nce8kRkAurrwLXNW0zObz",
            redirectUri: "https://telehealth.myctca.com/callback",
            domain: "auth.myctca.com",
            audience: "http://portalservice.ctca.prod/api"
        },
    }
};

const ENVIRONMENT_PER_HOST: any = {
    'localhost:3000': process.env.REACT_APP_ENV || 'mock',
    'localhost:4200': process.env.REACT_APP_ENV || 'mock',
    'localhost': process.env.REACT_APP_ENV || 'mock',
    'dev.cancercenter.com': 'dev',
    'dev.telehealth.myctca.com': 'dev',
    'qa.telehealth.myctca.com': 'qa',
    'stage.cancercenter.com': 'stage',
    'stage.telehealth.myctca.com': 'stage',
    'www.cancercenter.com': 'prod',
    'cancercenter.com': 'prod',
    'telehealth.myctca.com': 'prod',
};

export const CONFIG =
{
    requestPath: "/telehealth/meeting/",
    tokenPath: "/com-token",
    ...CONFIG_PER_ENVIRONMENT[ENVIRONMENT_PER_HOST[window.location.host]]
};


