import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Card } from '../../shared/Card/Card';
import { Page } from '../../shared/Page/Page';
import style from './Link.module.scss';
import { useAuth0 } from "@auth0/auth0-react";
import { CONFIG } from '../../config';
import axios from 'axios';
import { parseQuery } from '../../shared/utils';
import { Spinner } from '../../shared/Spinner/Spinner';
import jwt_decode from "jwt-decode";

const getCodeFromLocation = (location: Location): string | null => {
    const split = location.toString().split('?')
    if (split.length < 2) {
        return null
    }
    const pairs = split[1].split('&')
    for (const pair of pairs) {
        const [key, value] = pair.split('=')
        if (key === 'code') {
            return decodeURIComponent(value || '')
        }
    }
    return null
}

const code = getCodeFromLocation(window.location);
if (code !== null) {
    const pkceObj = JSON.parse(window.localStorage.getItem('pkce') || '{}');
    pkceObj.code = code;
    window.localStorage.setItem('pkce_r', JSON.stringify(pkceObj))
}

export const Link = () => {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
    const [meetingId, setMeetingId] = useState('');
    // const newWindow = useRef(window as any);

    useEffect(() => {
        const qsObj = parseQuery();
        let _meetingId = qsObj.meetingId;
        if (_meetingId) {
            localStorage.setItem('meetingId', _meetingId);
        } else {
            _meetingId = localStorage.getItem('meetingId');
        }
        if (_meetingId) {
            setMeetingId(_meetingId)
        }


    }, []);

    const forceRelogin = useCallback(() => {
        alert('There was an error, please try to login again');
        logout({
            logoutParams: {
                federated: true,
                returnTo: CONFIG.auth0.redirectUri
            }
        });

        // logout({ returnTo: CONFIG.auth0.redirectUri } as any);

    }, [logout]);

    useEffect(() => {
        console.log('isAuthenticated: ', isAuthenticated);

        if (isAuthenticated && meetingId) {
            (async () => {
                setLoading(true);
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        scope: 'openid external.identity.readwrite api email profile impersonation',
                    },
                    detailedResponse: true,
                    cacheMode: 'off',
                });
                console.log("AccessToken: ", accessToken)
                const contactInfoUrl = CONFIG.portalApiBaseUrl + '/api/v1/contactinfo/gettelehealthlinkcontacts?meetingId=' + encodeURIComponent(meetingId);

                const bearer = accessToken.id_token;
                var decoded: any = jwt_decode(bearer);
                if ((decoded.email || '').indexOf('@ctca-hope.com') < 0) {
                    setLoading(false);
                    forceRelogin();
                    return;
                }

                axios({
                    headers: {
                        Authorization: `Bearer ${bearer}`,
                        Accept: 'application/json, text/plain, */*'
                    },
                    method: 'GET',
                    url: contactInfoUrl
                }).then((response) => {
                    console.log(response.data);
                    setEmail(response.data.email);
                    setPhone(response.data.phone);
                }).catch(error => {
                    console.log(error);
                    forceRelogin();
                }).finally(() => {
                    setLoading(false);
                });
            })();
        }
    }, [isAuthenticated, meetingId, getAccessTokenSilently, forceRelogin]);


    const login = useCallback(() => {
        window.localStorage.removeItem('pkce_r');
        window.location.href = '/login';
        return;
    }, []);


    const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }
    const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(e.target.value)
    }

    const sendLink = useCallback(async (sendBy: string, destination: string) => {
        setLoading(true);
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                scope: 'openid external.identity.readwrite api email profile impersonation',
            },
            detailedResponse: true,
            cacheMode: 'off',
        });
        const contactInfoUrl = CONFIG.portalApiBaseUrl + '/api/v1/contactinfo/sendtelehealthlink';

        const bearer = accessToken.id_token;


        axios.post(contactInfoUrl, {
            sendBy,
            destination,
            meetingId
        }, {
            headers: {
                Authorization: `Bearer ${bearer}`,
                Accept: 'application/json, text/plain, */*'
            }
        }).then((response) => {
            console.log(response.data);
            alert(`The ${sendBy} was sent successfully`);
        }).catch(error => {
            console.log(error);
            forceRelogin();
        }).finally(() => {
            setLoading(false);
        });
    }, [meetingId, getAccessTokenSilently, forceRelogin]);

    const sendViaEmail = useCallback(() => {
        sendLink('email', email);
    }, [email, sendLink]);

    const sendViaSMS = useCallback(() => {
        sendLink('sms', phone);
    }, [phone, sendLink]);

    if (!isAuthenticated) {
        return (
            <Page>
                <Card>
                    <input type="button" value="Login" id="nextButton" onClick={login} />
                </Card>
            </Page>
        );
    }

    return (
        <Page>
            <div className={style.container}>
                <Card style={{ width: '100%', maxWidth: 400 }}>
                    <div className="inputNameLabel">
                        <p>
                            Send the telehealth link to the patient via email or sms<br />
                        </p>
                    </div>
                </Card>
                <Card style={{ width: '100%', maxWidth: 400 }}>
                    <div>
                        Email: <input type="text" id="email" style={{ textAlign: 'left', paddingLeft: 15 }} value={email} onChange={onEmailChange} />
                    </div>
                    <div className={style.sendButton}>
                        <input type="button" value="Send via Email" id="nextButton" onClick={sendViaEmail} />
                    </div>
                </Card>
                <Card style={{ width: '100%', maxWidth: 400 }}>
                    <div>
                        Mobile phone: <input type="text" id="phone" style={{ textAlign: 'left', paddingLeft: 15 }} value={phone} onChange={onPhoneChange} />
                    </div>
                    <div className={style.sendButton}>
                        <input type="button" value="Send via SMS" id="nextButton" onClick={sendViaSMS} />
                    </div>

                </Card>
            </div>
            <Spinner enabled={loading}></Spinner>
        </Page>
    );
};