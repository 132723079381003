import { AudioDeviceInfo, Call, DeviceManager } from "@azure/communication-calling";
import { logError } from "../shared/logging";

export class AudioInProperties {

    isOn: boolean;
    callHandle?: Call;
    deviceManager?: DeviceManager;
    availableMics?: AudioDeviceInfo[];

    constructor(isOn: boolean = false, callHandle?: Call, deviceManager?: DeviceManager, availableMics?: AudioDeviceInfo[]) {
        this.isOn = isOn;
        this.callHandle = callHandle;
        this.deviceManager = deviceManager;
        this.availableMics = availableMics;
    }
}

export async function handleMics(props: AudioInProperties, micName?: string) {
    init(props).then(async () => {
        if (micName) {
            props.availableMics?.forEach((mic) => {
                if (mic.name === micName) {
                    props.deviceManager?.selectMicrophone(mic)
                        .then(() => {
                            console.log("Audio In Updated: " + props.deviceManager?.selectedMicrophone?.name)
                        });
                }
            });
        } else if (props.availableMics && props.availableMics.length > 0) {
            props.deviceManager?.selectMicrophone(props.availableMics[0])
                .then(() => { console.log("Audio In Updated: " + props.deviceManager?.selectedMicrophone?.name) });
        }

        console.log("Audio In: " + props.deviceManager?.selectedMicrophone?.name);
    });
}

export function muteMicrophone(props: AudioInProperties) {
    props.isOn = false;
    if (props.callHandle && !props.callHandle.isMuted)
        props.callHandle.mute()
            .then(() => {
                console.log("call muted")
            })
            .catch((e) => {
                console.error("mute failed", e)
            });
}

export function unmuteMicrophone(props: AudioInProperties) {
    props.isOn = true;
    if (props.callHandle && props.callHandle.isMuted) props.callHandle.unmute().then(() => {
        console.log("call unmuted")
    });
}

async function init(props: AudioInProperties) {
    try {
        if (!props.deviceManager) {
            logError("Device Manager not initialized in AudioIn");
        }
        if (!props.availableMics) props.availableMics = await props.deviceManager?.getMicrophones();
        if (!props.deviceManager?.selectedMicrophone && props.availableMics && props.availableMics.length > 0)
            await props.deviceManager?.selectMicrophone(props.availableMics[0]);
    }
    catch (e) {
        console.error(e);
        props.isOn = false;
    }
}