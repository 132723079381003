import { ScreenSelector } from './screens/ScreenSelector';
import './css/caller.css';
import './css/header.css';
import './css/styles2.css';
import { initFirebase, trackPageTime } from './shared/tracking';
import { initDatadog } from './shared/logging';
import { AppContextProvider } from './shared/AppContextProvider/AppContextProvider';
import { useEffect } from 'react';




export const App = () => {

  useEffect(() => {
    initDatadog();
    initFirebase();
    window.onbeforeunload = () => {
      trackPageTime();
    }
    return () => {
      window.onbeforeunload = null;
    }
  }, []);

  return (
    <AppContextProvider>
      <ScreenSelector />
    </AppContextProvider>
  );
}