import { CONFIG } from "../config";
import { trackEvent } from "../shared/tracking";

export function fetchMore(endpoint: string, input: RequestInfo, init?: RequestInit, mockedResponse?: any): Promise<Response> {
    if (CONFIG.requestHost === 'mock' && mockedResponse) {
        return new Promise<Response>((resolve) => {
            setTimeout(() => {
                resolve(new Response(JSON.stringify(mockedResponse)));
            }, 1000);
        });
    }
    return new Promise((resolve, reject) => {
        const startTime = Date.now();
        fetch(input, init).then(value => {
            const endTime = Date.now();
            trackEvent('API Response Time - Success', {
                endpoint,
                time: (endTime - startTime) / 1000,
            });
            resolve(value);
        }).catch(e => {
            const endTime = Date.now();
            trackEvent('API Response Time - Error', {
                endpoint,
                time: (endTime - startTime) / 1000
            });
            reject(e);
        });
    });
};