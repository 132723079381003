import { LocalVideoStream, RemoteVideoStream } from "@azure/communication-calling";
import { MEETING_RESULT, MILLI_PER_MIN } from "../constants";

/*
* Obtain the required values for the page from the query string
*/
export function parseQuery() {
    var query: any = {},
        queryString = document.location.search,
        pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&'),
        pair,
        i;

    for (i = 0; i < pairs.length; i += 1) {
        pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

/*
* Validate appointment date againt current time
*/
export function checkDateStatus(jsonDate: any, durationMinutes: any) {
    var meetingTime = new Date(jsonDate),
        meetingStartBufferTime = new Date(meetingTime.getTime() - 720 * MILLI_PER_MIN), //meeting time minus 720 minutes (12 hrs)
        meetingEndTime = new Date(meetingTime.getTime() + (durationMinutes + 720) * MILLI_PER_MIN), //meeting end time plus 720 minutes (12hrs)
        currentTime = new Date();

    if (currentTime < meetingStartBufferTime) {
        return MEETING_RESULT.MEETING_UPCOMING;
    } else if (currentTime > meetingStartBufferTime && currentTime < meetingEndTime) {
        return MEETING_RESULT.MEETING_ONGOING;
    } else {
        return MEETING_RESULT.MEETING_COMPLETED;
    }
}

// find out if this is a mobile device
export function iOSorAndroid() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
        'Android'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        || (navigator.userAgent.includes("Android"))
}


export function iOSversion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
        var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/) as any;
        return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    }
}

export function simplifyRemoteVideoStream(videoStream: RemoteVideoStream) {
    return {
        id: videoStream.id,
        isAvailable: videoStream.isAvailable,
        mediaStreamType: videoStream.mediaStreamType,
        size: videoStream.size,
    }
}

export function simplifyRemoteVideoStreams(videoStreams: RemoteVideoStream[]) {
    return videoStreams.map(vs => simplifyRemoteVideoStream(vs));
}

export function simplifyLocalVideoStream(videoStream: LocalVideoStream) {
    return {
        name: videoStream.source.name,
        deviceType: videoStream.source.deviceType,
        id: videoStream.source.id,
        mediaStreamType: videoStream.mediaStreamType,
    }
}

export function simplifyLocalVideoStreams(videoStreams: LocalVideoStream[]) {
    return videoStreams.map(vs => simplifyLocalVideoStream(vs));
}