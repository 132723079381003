import { datadogLogs, StatusType } from "@datadog/browser-logs";
import { CONFIG } from "../config";

let order = 0;
const circularReplacer = () => {

    // Creating new WeakSet to keep 
    // track of previously seen objects
    const seen = new WeakSet();

    return (key: any, value: any) => {

        // If type of value is an 
        // object or value is null
        if (typeof (value) === "object"
            && value !== null) {

            // If it has been seen before
            if (seen.has(value)) {
                return;
            }

            // Add current value to the set
            seen.add(value);
        }

        // return the value
        return value;
    };
};

function takeOverConsole() {
    var console = window.console as any;
    if (!console) return
    function intercept(method: string) {
        var original = console[method]
        console[method] = function () {

            // original(arguments[0], arguments[1]);
            if (original.apply) {
                // Do this for normal browsers
                original.apply(console, arguments)
            } else {
                // Do this for IE
                var message = Array.prototype.slice.apply(arguments).join(' ')
                original(message)
            }

            // do sneaky stuff
            let logArgs: any = {};
            if (arguments.length > 1) {
                console.debug(typeof arguments[1]);
            }
            if (arguments.length > 1 && typeof arguments[1] === 'object') {
                logArgs = { ...arguments[1] };
            }
            for (let i = 1; i < arguments.length; i++) {
                logArgs['arg' + i] = arguments[i];
            }
            logLevel(arguments[0], logArgs, (StatusType as any)[method]);

        }
    }
    var methods = ['log', 'warn', 'error'];
    for (var i = 0; i < methods.length; i++)
        intercept(methods[i])
}

export const initDatadog = () => {
    takeOverConsole();
    datadogLogs.init({
        clientToken: CONFIG.datadogClientToken,
        // forwardErrorsToLogs: false,
        // forwardConsoleLogs: 'all',
        forwardReports: 'all',
        sampleRate: 100,
        env: CONFIG.environment,
        service: 'ctca.telehealth.web',
        telemetrySampleRate: 100,
    });
}

export const logger = datadogLogs.logger;

export const logLevel = (message: string, context?: any, status?: StatusType) => {
    let cleanContext = context;
    if (cleanContext) {
        try {
            cleanContext = JSON.parse(JSON.stringify(context, circularReplacer()));
        } catch (e) {
            // Nothing to do here
        }
    } else {
        cleanContext = {};
    }
    cleanContext.order = order;
    logger.log(message, cleanContext, status);
    order++;
}
export const logInfo = (message: string, context?: any) => { logLevel(message, context, StatusType.info) }
export const logWarn = (message: string, context?: any) => { logLevel(message, context, StatusType.warn) }
export const logDebug = (message: string, context?: any) => { logLevel(message, context, StatusType.debug) }
export const logError = (message: string, context?: any) => { logLevel(message, context, StatusType.error) }