import React from 'react'
import style from './Popup.module.scss';
import { css } from '../style';

interface PopupProps {
    children?: any;
    showing: boolean;
    removePadding?: boolean;
}

export const Popup = (props: PopupProps) => {

    if (!props.showing) {
        return <></>;
    }
    return (
        <div className={style.overlay}>
            <div className={css({
                [style.content]: true,
                [style.withPadding]: !props.removePadding
            })}>
                {props.children}
            </div>
        </div>
    );
};