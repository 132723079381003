import { CallAgent, DeviceManager } from "@azure/communication-calling";
import React, { Dispatch, SetStateAction, useState } from "react";
import { TelehealthMeetingResponse } from "../../API/models";
import { AudioInProperties } from "../../components/Utility-AudioIn";
import { AudioOutProperties } from "../../components/Utility-AudioOut";
import { VideoProperties } from "../../components/Utility-Video";
import { ROUTES } from "../../constants";

export type GetterSetter<T> = [T, Dispatch<SetStateAction<T>>];


export interface ContextType {
  showScreen: GetterSetter<string>;
  theResult: GetterSetter<TelehealthMeetingResponse | undefined>;
  deviceType: GetterSetter<string>;
  deviceOrientation: GetterSetter<string>;
  patientName: GetterSetter<string>;
  cameraOn: GetterSetter<boolean>;
  microphoneOn: GetterSetter<boolean>;
  videoProperties: GetterSetter<VideoProperties>;
  audioOutProperties: GetterSetter<AudioOutProperties>;
  audioInProperties: GetterSetter<AudioInProperties>;
  callAgent: GetterSetter<CallAgent | undefined>;
  deviceManager: DeviceManager | undefined;

}

export const AppContext = React.createContext<ContextType>({} as any);


interface AppContextProviderProps {
  initialScreen?: string;
  children: any;
}

export const AppContextProvider = (props: AppContextProviderProps) => {
  const context: ContextType = {
    showScreen: useState(window.location.pathname || props.initialScreen || ROUTES.init),
    theResult: useState(),
    deviceType: useState('desktop'),
    deviceOrientation: useState('landscape'),
    patientName: useState(''),
    cameraOn: useState<boolean>(false),
    microphoneOn: useState<boolean>(true),
    videoProperties: useState(new VideoProperties()),
    audioOutProperties: useState(new AudioOutProperties()),
    audioInProperties: useState(new AudioInProperties()),
    callAgent: useState<CallAgent | undefined>(),
    deviceManager: undefined,

  };

  return (
    <AppContext.Provider value={context}>
      {props.children}
    </AppContext.Provider>
  );
}