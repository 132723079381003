export const ROUTES = {
    init: '/',
    welcome: '/welcome',
    devices: '/devices',
    ready: '/ready',
    meeting: '/meeting',
    future: '/future',
    ended: '/ended',
    error: '/error',
    unsupportedIos: '/unsupported-ios',
    link: '/link',
    callback: '/callback',
    login: '/login'
};

export const MEETING_RESULT = {
    MEETING_UPCOMING: 1,
    MEETING_ONGOING: 0,
    MEETING_COMPLETED: -1,
}

export const MILLI_PER_MIN = 60000;

export const IMAGES = {
    hangup: require('./assets/hangup.svg').default,
    micOff: require('./assets/mic-off.svg').default,
    micOn: require('./assets/mic-on.svg').default,
    videoOff: require('./assets/video-off.svg').default,
    videoOn: require('./assets/video-on.svg').default,
}