import React, { useContext } from 'react'
import { ROUTES } from '../../constants';
import { AppContext } from '../AppContextProvider/AppContextProvider';
import style from './Page.module.scss';
interface PageProps {
    children: any;
}

export const Page = (props: PageProps) => {
    const context = useContext(AppContext);
    const [deviceOrientation] = context.deviceOrientation;
    const [deviceType] = context.deviceType;
    const [showScreen] = context.showScreen;
    const mobileCallTechSupport = () => {
        window.open("tel:18002340482");
    };

    const desktopDefaultHeader = () => {
        return (
            <header id="mainHeader">
                <div className="mainHeaderContainer">
                    <div className="headerLogo">
                        <img className={style.logo} src={process.env.PUBLIC_URL + '/assets/img/ctca-logo-small.png'} alt="Cancer Treatment Centers of America" width="175" height="64" />
                    </div>

                    <div className="techSupportPhoneGraphic">
                        <img src={process.env.PUBLIC_URL + '/assets/img/Icon_Phone.svg'} alt="" />
                    </div>
                    <div className="techSupportContainer">
                        <div className="techSupportLabel">
                            <p>Tech Support</p>
                        </div>
                        <div className="techSupportPhone">
                            <p><a href="tel:18002340482">(800) 234-0482</a></p>
                        </div>
                    </div>
                </div>
            </header>
        );
    }

    const mobilePortraitHeader = () => {
        return (
            <header id="mainHeader">
                <div className="mainHeaderContainer">
                    <div className="headerLogo">
                        <img className={style.logo} src={process.env.PUBLIC_URL + '/assets/img/ctca-logo-small.png'} alt="Cancer Treatment Centers of America" width="175" height="64" />
                    </div>

                    <div className="techSupportContainer">

                        <button className="techSupportButton" onClick={mobileCallTechSupport}>
                            <span className="techSupportPhoneGraphicMobile">
                                <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.5846 16.3586L18.4702 16.1251C18.15 15.3544 14.216 14.1868 13.8958 14.1634L13.6442 14.1868C13.1639 14.2802 12.6378 14.7239 11.6085 15.6347C11.4027 15.8215 11.1282 15.8682 10.8766 15.7281C9.52717 14.9574 7.88037 13.4162 7.05696 12.4821C6.16495 11.4779 5.08995 9.81985 4.58676 8.48876C4.49527 8.23188 4.58676 7.95164 4.76974 7.76482C5.93623 6.6906 6.43941 6.17685 6.48516 5.61638C6.50803 5.28945 5.82186 1.15603 5.11282 0.759036L4.90697 0.61892C4.44953 0.315336 3.76336 -0.128364 3.00858 0.0351043C2.8256 0.0818095 2.64262 0.151867 2.48252 0.245278C1.97933 0.572215 0.721354 1.45961 0.149548 2.60389C-0.193535 3.32783 -0.353641 9.88991 4.44953 15.4245C9.20695 20.9124 15.0851 21.1459 15.9543 20.9591H15.9771L16.0458 20.9357C17.2351 20.492 18.2415 19.3477 18.6303 18.8573C19.3394 17.9933 18.859 16.9424 18.5846 16.3586Z" fill="white" />
                                </svg>
                            </span>
                            <div className="techSupportLabelMobileContainer">
                                <span className="techSupportLabelMobile">Tech Support</span>
                            </div>
                            <div className="techSupportNumberMobileContainer">
                                <span className="techSupportNumberMobile">(800) 234-0482</span>
                            </div>

                        </button>
                    </div>
                </div>
            </header>
        );
    }

    const mobileLandscapeHeader = () => {
        return (
            <div className={style.verticalHeaderContainer}>
                <div className={style.verticalTechSupportContainer}>
                    <button className="verticalTechSupportButton" onClick={mobileCallTechSupport}>
                        <span className="techSupportPhoneGraphicMobile">
                            <svg width="19" height="21" viewBox="0 0 19 21" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18.5846 16.3586L18.4702 16.1251C18.15 15.3544 14.216 14.1868 13.8958 14.1634L13.6442 14.1868C13.1639 14.2802 12.6378 14.7239 11.6085 15.6347C11.4027 15.8215 11.1282 15.8682 10.8766 15.7281C9.52717 14.9574 7.88037 13.4162 7.05696 12.4821C6.16495 11.4779 5.08995 9.81985 4.58676 8.48876C4.49527 8.23188 4.58676 7.95164 4.76974 7.76482C5.93623 6.6906 6.43941 6.17685 6.48516 5.61638C6.50803 5.28945 5.82186 1.15603 5.11282 0.759036L4.90697 0.61892C4.44953 0.315336 3.76336 -0.128364 3.00858 0.0351043C2.8256 0.0818095 2.64262 0.151867 2.48252 0.245278C1.97933 0.572215 0.721354 1.45961 0.149548 2.60389C-0.193535 3.32783 -0.353641 9.88991 4.44953 15.4245C9.20695 20.9124 15.0851 21.1459 15.9543 20.9591H15.9771L16.0458 20.9357C17.2351 20.492 18.2415 19.3477 18.6303 18.8573C19.3394 17.9933 18.859 16.9424 18.5846 16.3586Z"
                                    fill="white" />
                            </svg>
                        </span>
                        <div className="techSupportLabelMobileContainer">
                            <span className="techSupportLabelMobile">Tech Support</span>
                        </div>
                        <div className="techSupportNumberMobileContainer">
                            <span className="techSupportNumberMobile">(800) 234-0482</span>
                        </div>
                    </button>
                </div>

                <div className={style.verticalHeaderLogo}>
                    <img className={style.logo} src={process.env.PUBLIC_URL + '/assets/img/ctca-logo-small.png'} alt="Cancer Treatment Centers of America" width="175" height="64" />
                </div>
            </div>
        );
    }

    let className = style.portraitPage
    let header;
    if (deviceType === 'desktop') {
        header = desktopDefaultHeader();
    } else if (deviceOrientation === 'portrait' || showScreen !== ROUTES.meeting) {
        header = mobilePortraitHeader();
    } else {
        header = mobileLandscapeHeader();
        className = style.landscapePage;
    }

    return (<div className={className}>
        {header}
        {props.children}
    </div>)

};