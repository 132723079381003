import { CallClient, DeviceManager } from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import React, { useContext, useEffect, useState } from 'react'
import { fetchMore } from '../../API/fetchMore';
import { subscribeToRemoteVideo } from '../../components/Utility-Video';
import { CONFIG } from '../../config';
import { ROUTES } from '../../constants';
import { AppContext } from '../../shared/AppContextProvider/AppContextProvider';
import { Card } from '../../shared/Card/Card';
import { logError, logInfo } from '../../shared/logging';
import { MeetingInfoCard } from '../../shared/MeetingInfoCard/MeetingInfoCard';
import { Page } from '../../shared/Page/Page';
import { Spinner } from '../../shared/Spinner/Spinner';
import { trackEvent } from '../../shared/tracking';
import { iOSorAndroid } from '../../shared/utils';
import style from './Devices.module.scss';

let tokenResponse: any;
try {
    const mockData = require('../../API/mockData');
    tokenResponse = mockData?.tokenResponse || {};
} catch (e) {
    tokenResponse = {};
}

export const Devices = () => {
    const context = useContext(AppContext);
    const [, setShowScreen] = context.showScreen;
    const [patientName] = context.patientName;
    const [greeting, setGreeting] = useState('Welcome!');
    const [videoProperties] = context.videoProperties;
    const [audioOutProperties] = context.audioOutProperties;
    const [audioInProperties] = context.audioInProperties;
    const [, setCallAgent] = context.callAgent;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (patientName) {
            setGreeting('Welcome,')
        } else {
            setGreeting('Welcome!')
        }
    }, [patientName]);

    /*
    * Request token from endpoint
    */
    function requestToken():Promise<string> {
        return new Promise<string>((resolve, reject) => {

            // var lclStgKey: string = "comKey",
            //     expiryKey: string = "tExpiry",
            //     expDateString: string | null = "";

            // expDateString = localStorage.getItem(expiryKey);
            // if (expDateString === null) {
            //     expDateString = new Date().toISOString();
            // }

            // let serviceToken: string | null = localStorage.getItem(lclStgKey);
            // if (!serviceToken || (new Date(expDateString) <= new Date())) {
            let serviceToken: string | null;
                fetchMore(CONFIG.tokenPath, new Request(CONFIG.requestHost + CONFIG.tokenPath, {
                    method: 'GET',
                }), undefined, tokenResponse).then((responseObj) => {
                    if (!responseObj || !responseObj.ok) {
                        logError('Token API Error', responseObj);
                        setShowScreen(ROUTES.error);
                        reject();
                        return;
                    }
                    responseObj.json().then(response => {
                        if (response === null || response.value === null || response.value.token === null) {
                            logError('Token API Error', response);
                            setShowScreen(ROUTES.error);
                            reject();
                            return;
                        }
                        logInfo('Token API Response', response);
                        console.log("serviceToken response...");
                        console.log(response);
                    serviceToken = response.value.token;
                        if (serviceToken) {
                            // localStorage.setItem(lclStgKey, serviceToken);
                            // localStorage.setItem(expiryKey, response.value.expiresOn);
                            resolve(serviceToken);
                        } else {
                            logError('Token API Error Token', serviceToken as any);
                            reject();
                        }
                    });
                }).catch(err => {
                    logError('Token API Exception', err);
                    serviceToken = null;
                    console.log("token could not be retrieved. show now.");
                    setShowScreen(ROUTES.meeting);
                    reject();
                })

            // } else {
            //     resolve(serviceToken);
            // }
        });
    }

    /* ***********************************************************************************
    /* Azure Communication Services functionality
    /*
    /* *********************************************************************************** */

    /*
    * Initialize call, check for available devices
    */
    async function init(comToken: string) {
        console.log("Initializing process for Teams meeting...");
        console.log('comToken: ', comToken);

        const callClient = new CallClient();
        const tokenCredential = new AzureCommunicationTokenCredential(comToken);

        const _callAgent = await callClient.createCallAgent(tokenCredential, { displayName: patientName || 'Remote patient' });
        setCallAgent(_callAgent);

        const deviceManager = await callClient.getDeviceManager();

        //const result = 
        const permissions = await deviceManager.askDevicePermission({ audio: true, video: true });
        trackEvent(`Devices Screen - Video permission ${permissions.video ? 'allowed' : 'denied'}`);
        trackEvent(`Devices Screen - Audio permission ${permissions.audio ? 'allowed' : 'denied'}`);

        try {
            if (permissions.video && permissions.audio) {
                const devices: any = {
                    audioinput: [],
                    videoinput: [],
                    audiooutput: [],
                };
                navigator.mediaDevices.enumerateDevices().then(de => {
                    de.forEach(d => {
                        if (d.deviceId === 'default') {
                            devices[d.kind].push('(Default) ' + d.label);
                        } else {
                            devices[d.kind].push(d.label);
                        }

                    });
                    console.log('Enumerate devices', devices);
                });
            }
        } catch (e) {
            console.error('Enumeration failed');
        }

        await setDeviceManagers(deviceManager);

        _callAgent.on('incomingCall', async (e) => {
            // ###### Uncomment next 3 lines ######
            // handleLocalVideo(videoProperties, ctBarVideoButton.classList.contains(buttonActive), ctBarVideoButton);
            // const addedCall = await e.incomingCall.accept(ctBarVideoButton.classList.contains(buttonActive) ? videoProperties.callOptions : {});
            // call = addedCall;

            subscribeToRemoteVideo(videoProperties, null);

            console.log("Caller has subscribed...");
        });

        // Subscribe to call updates
        // You need to subscribe to the event when the remote participant ends the call to dispose of video renderers and toggle button states. 
        _callAgent.on('callsUpdated', (e) => {
            e.removed.forEach((removedCall) => {
                videoProperties.rendererLocal?.dispose();
                videoProperties.rendererRemote?.dispose();
                // videoProperties.localVideoStream?.dispose();
            });
        })

    // find out if this is a IOS device
        if (iOSorAndroid()) {
            return 'success';
        } else if (videoProperties.availableCams && audioOutProperties.availableSpeakers && audioInProperties.availableMics) {
            return 'success';
        } else {
            console.warn('Device enumeration failed');
            // return 'failure';
            return 'success';
        }

    }

    /*
    * Get the list of devices (cameras, microphones, speakers)
    */
    async function setDeviceManagers(manager: DeviceManager) {

        videoProperties.deviceManager = manager;
        audioInProperties.deviceManager = manager;
        audioInProperties.isOn = true; //turn on microphone by default
        audioOutProperties.deviceManager = manager;

    // find out if this is a IOS device
        if (iOSorAndroid()) {
            // enumeration of devices does not work in IOS using Azure Communication Services
            console.log("Enumeration of devices does not work in mobile devices using Azure Communication Services");
        } else {
            try {
                videoProperties.availableCams = await manager.getCameras();
                logInfo('Detected Cameras', videoProperties.availableCams.map(c => c.name));
            } catch (e) {
                console.error('Cameras enumeration failed:', e);
            }

            try {
                audioInProperties.availableMics = await manager.getMicrophones();
                logInfo('Detected Microphones', audioInProperties.availableMics);
            } catch (e) {
                console.error('Microphones enumeration failed:', e);
            }

            try {
                audioOutProperties.availableSpeakers = await manager.getSpeakers();
                logInfo('Detected Speakers', audioOutProperties.availableSpeakers);
            } catch (e) {
                console.error('Speakers enumeration failed:', e);
            }
        }

    }

    const theCheckAvailableDevices = async () => {
        setLoading(true);

        // call the async process to obtain the azure service token
        requestToken().then(async (serviceToken) => {
            var resultCheck = await init(serviceToken || '');
            setLoading(false);
            console.log('resultCheck:', resultCheck);
            if (resultCheck === "success") {
                setShowScreen(ROUTES.ready);
            } else {
                setShowScreen(ROUTES.error);
            }
        }).catch((e) => {
            console.error("Meeting error. show now", e);
            setLoading(false);
            setShowScreen(ROUTES.error);
        });
    }

    return (
        <Page>
            <div className={style.container} id="check-devices-content">
                <MeetingInfoCard></MeetingInfoCard>
                <Card>
                    <div className="checkDevicesMessage">
                        <p className={style.greeting}>{greeting} {patientName}</p>
                        <p>
                            In order for your doctor to see and hear you, your browser will
                            request <b>Microphone</b> and <b>Camera</b> access.
                        </p>
                        <p className={style.footer}>You can turn this back off anytime.</p>
                    </div>
                    <div className="checkDevicesNext">
                        <input type="button" value="Next" id="nextDevicesButton" onClick={theCheckAvailableDevices} />
                    </div>
                </Card>
            </div>
            <Spinner enabled={loading}></Spinner>
        </Page>
    );
};