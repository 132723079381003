import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

const SSO = () => {
    const { loginWithRedirect } = useAuth0();
    const login = async () => loginWithRedirect({
        authorizationParams: {
            scope: 'openid external.identity.readwrite api email profile impersonation',
        },
    });
    const logout = async () => {
        window.localStorage.removeItem('pkce_r');
        window.localStorage.removeItem('pkce')
        window.localStorage.removeItem('auth')
    }

    useEffect(() => {
        logout();
        window.localStorage.setItem('preAuthUri', '/link')
        login();
        window.localStorage.setItem('preAuthUri', '/link')
    }, []);

    return (<></>)
}

export default SSO