import { AudioDeviceInfo, Call, DeviceManager } from "@azure/communication-calling";
import { logError } from "../shared/logging";

export class AudioOutProperties {
    isOn: boolean;
    callHandle?: Call;
    deviceManager?: DeviceManager;
    availableSpeakers?: AudioDeviceInfo[];

    constructor(isOn: boolean = false, callHandle?: Call, deviceManager?: DeviceManager, availableSpeakers?: AudioDeviceInfo[]) {
        this.isOn = isOn;
        this.callHandle = callHandle;
        this.deviceManager = deviceManager;
        this.availableSpeakers = availableSpeakers;
    }
}

export async function handleSpeakers(props: AudioOutProperties, speakerName?: string) {
    init(props).then(async () => {

        console.log("Audio Out: " + props.deviceManager?.selectedSpeaker?.name);
        if (speakerName) {
            props.availableSpeakers?.forEach((speaker: AudioDeviceInfo) => {
                if (speaker.name === speakerName) {
                    props.deviceManager?.selectSpeaker(speaker)
                        .then(() => {
                            console.log("Audio Out Updated: " + props.deviceManager?.selectedSpeaker?.name)
                        });
                }
            });
        } else if (props.availableSpeakers && props.availableSpeakers.length > 0) {
            props.deviceManager?.selectSpeaker(props.availableSpeakers[0])
                .then(() => { console.log("Audio Out Updated: " + props.deviceManager?.selectedSpeaker?.name) });
        }
    });
}

async function init(props: AudioOutProperties) {
    try {
        if (!props.deviceManager) {
            logError("Device Manager not initialized in AudioOut");
            alert("Error starting the call, if the issue persists please call Tech Support at (800) 234-0482");
        }
        if (!props.availableSpeakers) props.availableSpeakers = await props.deviceManager?.getSpeakers();
        if (!props.deviceManager?.selectedSpeaker && props.availableSpeakers && props.availableSpeakers.length > 0)
            await props.deviceManager?.selectSpeaker(props.availableSpeakers[0]);
    } catch (e) {
        console.log(e);
        props.isOn = false;
    }
}