import React, { useCallback, useContext, useEffect, useState } from 'react'
import { MILLI_PER_MIN, ROUTES } from '../../constants';
import { AppContext } from '../../shared/AppContextProvider/AppContextProvider';
import { Page } from '../../shared/Page/Page';
import style from './Future.module.scss';

export const Future = () => {
    const context = useContext(AppContext);
    const [theResult] = context.theResult;
    const [, setShowScreen] = context.showScreen;

    const [appointmentDateOnly, setAppointmentDateOnly] = useState<string>();
    const [appointmentDayLong, setAppointmentDayLong] = useState<string>();
    const [appointmentTimeTimezone, setAppointmentTimeTimezone] = useState<string>();
    const [providerName, setProviderName] = useState<string>();

    /*
    * Check appointment time against current time
    */
    const checkUpcomingMeeting = useCallback((meetingDetail: any) => {
        var meetingStart = new Date(meetingDetail.appointmentDateTime),
            meetingAccessTime = new Date(meetingStart.getTime() - 15 * MILLI_PER_MIN),
            currentTime = new Date();

        if (currentTime < meetingAccessTime) {
            setTimeout(checkUpcomingMeeting.bind(null, meetingDetail), MILLI_PER_MIN);
        } else {
            setShowScreen(ROUTES.meeting);
        }
    }, [setShowScreen]);

    useEffect(() => {
        const meetingDetail = theResult?.result;
        if (meetingDetail) {
            const meetingStart = new Date(meetingDetail.appointmentDateTime);
            setAppointmentDateOnly(meetingStart.toLocaleString([], { month: 'long', day: 'numeric', year: 'numeric' }));
            setAppointmentDayLong(meetingStart.toLocaleString([], { weekday: 'long' }));
            setAppointmentTimeTimezone(meetingStart.toLocaleString([], { hour: 'numeric', minute: "2-digit", hour12: true, timeZoneName: 'short' }));
            setProviderName(meetingDetail.appointmentProviderName || undefined);
            checkUpcomingMeeting(meetingDetail);
        } else {
            setAppointmentDateOnly(undefined);
            setAppointmentDayLong(undefined);
            setAppointmentTimeTimezone(undefined);
            setProviderName(undefined);
        }
    }, [theResult, checkUpcomingMeeting]);

    return (
        <Page>
            <div className={style.futureMeetingContainer}>
                <h2>Your telehealth appointment is not until:</h2>
                <div className="meetingDetails">
                    <p>{appointmentDateOnly}</p>
                    <p>{appointmentDayLong}, {appointmentTimeTimezone}</p>
                    {providerName && <p>with {providerName}</p>}
                </div>
            </div>
        </Page>
    );
};