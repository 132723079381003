import React, { useContext } from 'react'
import { ROUTES } from '../../constants';
import { AppContext } from '../../shared/AppContextProvider/AppContextProvider';
import { Card } from '../../shared/Card/Card';
import { MeetingInfoCard } from '../../shared/MeetingInfoCard/MeetingInfoCard';
import { Page } from '../../shared/Page/Page';
import style from './Welcome.module.scss';

export const Welcome = () => {

    const context = useContext(AppContext);
    const [, setShowScreen] = context.showScreen;
    const [patientName, setPatientName] = context.patientName;

    const theCheckDevicesContent = () => {
        setShowScreen(ROUTES.devices);
    }

    const onFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPatientName(e.target.value)
    }

    return (
        <Page>
            <div className={style.container}>
                <MeetingInfoCard></MeetingInfoCard>
                <Card>
                    <div className="inputNameLabel">
                        <p>
                            Please enter your<br />
                            <b>FIRST NAME</b>
                        </p>
                    </div>
                    <div>
                        <input type="text" id="firstName" value={patientName} onChange={onFirstNameChange} />
                    </div>
                    <div className={style.welcomeNextButton}>
                        <input type="button" value="Next" id="nextButton" onClick={theCheckDevicesContent} />
                    </div>
                </Card>
            </div>
        </Page>
    );
};