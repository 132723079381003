import React from 'react'
import { Page } from '../../shared/Page/Page';
import style from './UnsupportedIos.module.scss';

interface UnsupportedIosProps {

}

export const UnsupportedIos = (props: UnsupportedIosProps) => {
    return (
        <Page>
            <div className={style.container}>
                <div className={style.main}>
                    <div className={style.mainTop}>
                        <p>We apologize for the inconvenience.</p>
                        <p>This browser version is not supported.</p>
                        <br />
                        <p>To join your telehealth appointment,</p>
                        <p>please do one of the following: </p>
                    </div>
                    <div className={style.mainLogo}>
                        <img src={process.env.PUBLIC_URL + '/assets/img/unsupported-ios.svg'} alt="" />
                    </div>
                    <div className={style.mainLogoText}>
                        <p>myCTCA app</p>
                    </div>
                    <div className={style.mainDownload}>
                        <a href="https://apps.apple.com/us/app/myctca/id1313005971">
                            <img src={process.env.PUBLIC_URL + '/assets/img/app-store-icon.png'} alt="Download myCTCA App" />
                        </a>
                    </div>
                    <div className={style.mainOrOption}>
                        <div className={style.mainOrOptionSeparator}></div>
                        <p>&nbsp; &nbsp; OR&nbsp; &nbsp; </p>
                        <div className={style.mainOrOptionSeparator}></div>
                    </div>
                    <div className={style.mainBottom}>
                        <p>Make sure you have iOS v15.2.0 or</p>
                        <p>later on your Apple device</p>
                    </div>
                    <div className={style.mainOrOption}>
                        <div className={style.mainOrOptionSeparator}></div>
                        <p>&nbsp; &nbsp; OR&nbsp; &nbsp; </p>
                        <div className={style.mainOrOptionSeparator}></div>
                    </div>
                    <div className={style.mainBottom}>
                        <p>Click on the email link</p>
                        <p> from a different device.</p>
                    </div>
                </div>
            </div>

        </Page >
    );
};