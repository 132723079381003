import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CONFIG } from '../config';
import { ROUTES } from '../constants';
import { AppContext } from '../shared/AppContextProvider/AppContextProvider';
import { useWindowSize } from '../shared/hooks';
import { trackPage } from '../shared/tracking';
import { Devices } from './Devices/Devices';
import { Ended } from './Ended/Ended';
import { Future } from './Future/Future';
import { Init } from './Init/Init';
import { Link } from './Link/Link';
import SSO from './Link/SSO';
import { Meeting } from './Meeting/Meeting';
import { UnsupportedIos } from './UnsupportedIos/UnsupportedIos';
import { Welcome } from './Welcome/Welcome';
import { Auth0Provider } from "@auth0/auth0-react";
interface ScreenSelectorProps {

}

export const ScreenSelector = (props: ScreenSelectorProps) => {
    const context = useContext(AppContext);
    const [showScreen] = context.showScreen;
    const { width, height } = useWindowSize();
    const [, setDeviceType] = context.deviceType;
    const [, setDeviceOrientation] = context.deviceOrientation;

    useEffect(() => {
        console.log('showScreen', showScreen);
        trackPage(showScreen);
    }, [showScreen]);

    /*
    * Ready Event - Create event to monitor resizing the window (mobile)
    */
    useEffect(() => {
        let deviceType: string;
        let deviceOrientation: string;

        // find out if this is a mobile device
        if (width && width <= 832) {
            deviceType = "mobile";
        } else {
            deviceType = "desktop";
        }

        // find out the original orientation
        if (width && height && width > height) {
            deviceOrientation = "landscape";
        } else {
            deviceOrientation = "portrait";
        }
        setDeviceType(deviceType);
        setDeviceOrientation(deviceOrientation);

    }, [width, height, setDeviceType, setDeviceOrientation]);

    return (
        <BrowserRouter>
            <Routes location={showScreen}>
                <Route path={ROUTES.init} element={<Init />} />
                <Route path={ROUTES.welcome} element={<Welcome />} />
                <Route path={ROUTES.devices} element={<Devices />} />
                <Route path={ROUTES.ready} element={<Meeting />} />
                <Route path={ROUTES.meeting} element={<Meeting />} />
                <Route path={ROUTES.future} element={<Future />} />
                <Route path={ROUTES.ended} element={<Ended />} />
                <Route path={ROUTES.error} element={<Ended />} />
                <Route path={ROUTES.unsupportedIos} element={<UnsupportedIos />} />
                <Route path={ROUTES.link} element={<Auth0Provider domain={CONFIG.auth0.domain} clientId={CONFIG.auth0.clientId} useRefreshTokens={true} authorizationParams={{
                    redirect_uri: CONFIG.auth0.redirectUri
                }} ><Link /></Auth0Provider>} />
                <Route path={ROUTES.callback} element={<Auth0Provider domain={CONFIG.auth0.domain} clientId={CONFIG.auth0.clientId} useRefreshTokens={true} authorizationParams={{
                    redirect_uri: CONFIG.auth0.redirectUri
                }} ><Link /></Auth0Provider>} />
                <Route path={ROUTES.login} element={<Auth0Provider domain={CONFIG.auth0.domain} clientId={CONFIG.auth0.clientId} useRefreshTokens={true} authorizationParams={{
                    redirect_uri: CONFIG.auth0.redirectUri
                }} ><SSO /></Auth0Provider>} />
            </Routes>
        </BrowserRouter>
    );
};