import React from 'react'
import style from './Card.module.scss';

interface CardProps {
    children: any;
    style?: any;
}

export const Card = (props: CardProps) => {
    return (
        <div className={style.content} style={props.style}>
            {props.children}
        </div>
    );
};