import React from 'react'
import style from './Alert.module.scss';
import { Popup } from '../Popup/Popup';
import { css } from '../style';

interface AlertButton {
    text: string;
    onClick?: () => void;
    isNegative?: boolean;
}

interface AlertProps {
    text: string;
    showing: boolean;
    buttons: AlertButton[];
}

export const Alert = (props: AlertProps) => {
    return (
        <Popup showing={props.showing} removePadding={true}>
            <div className={style.content}>
                {props.text}
            </div>
            {props.buttons.map(b =>
                <div
                    key={b.text}
                    onClick={b.onClick}
                    className={css({
                        [style.button]: true,
                        [style.negative]: !!b.isNegative
                    })}
                >
                    {b.text}
                </div>
            )}
        </Popup>
    );
};