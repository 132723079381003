import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { IMAGES, ROUTES } from '../../constants';
import { Page } from '../../shared/Page/Page';
import { handleLocalVideo, subscribeToRemoteVideo } from '../../components/Utility-Video';
import { handleMics, muteMicrophone, unmuteMicrophone } from '../../components/Utility-AudioIn';
import { handleSpeakers } from '../../components/Utility-AudioOut';
import style from './Meeting.module.scss';
import { trackEvent } from '../../shared/tracking';
// import { SettingsMenu } from './SettingsMenu';
import { Call } from '@azure/communication-calling';
import { Alert } from '../../shared/Alert/Alert';
import { AppContext } from '../../shared/AppContextProvider/AppContextProvider';
import { simplifyLocalVideoStreams } from '../../shared/utils';

interface MeetingProps {

}

export const Meeting = (props: MeetingProps) => {
    const context = useContext(AppContext);
    const [cameraOn, setCameraOn] = context.cameraOn;
    const [microphoneOn, setMicrophoneOn] = context.microphoneOn;
    const [showScreen, setShowScreen] = context.showScreen;
    const [patientName] = context.patientName;
    const [allSetLabel, setAllSetLabel] = useState('');
    const [theResult] = context.theResult;
    const [callAgent] = context.callAgent;
    const [videoProperties] = context.videoProperties;
    const [audioOutProperties] = context.audioOutProperties;
    const [audioInProperties] = context.audioInProperties;
    const [deviceType] = context.deviceType;
    const [deviceOrientation] = context.deviceOrientation;
    const [localVideoIsMain, setLocalVideoIsMain] = useState(false);
    const [videoWasUsed, setVideoWasUsed] = useState(false);
    const localVideoRef = useRef<HTMLDivElement>(null);
    const remoteVideoRef = useRef<HTMLDivElement>(null);
    const [startTime, setStartTime] = useState<number | undefined>(undefined);
    const [alreadyJoinedMeeting, setAlreadyJoinedMeeting] = useState(false);
    const joinMeetingTimeout = useRef<NodeJS.Timeout | null>(null);
    const [showLeaveAppointmentAlert, setShowLeaveAppointmentAlert] = useState(false);


    /*
    * Prepare the video area when this is a mobile device
    */
    const setupMobileDeviceVideo = useCallback(() => {
        // check if mobile device
        if (deviceType === "mobile") {
            console.log("Setting up the video for mobile devices...");

            // only replace header when div is the now meeting
            // var nowDiv = $("#now-apt").css("display");
            //console.log("nowDIV: " + nowDiv);
            // if (nowDiv != "none") {
            if (showScreen === ROUTES.meeting) {
                // updates based in the orientation portrait/landscape
                if (deviceOrientation === "portrait") {
                    // set background black
                    document.body.style.backgroundColor = "black";

                } else if (deviceOrientation === "landscape") {
                    document.body.style.backgroundColor = "#F7F7F7";
                }
            }
        } else if (deviceType === "desktop") {
            // set background black
            document.body.style.backgroundColor = "black";
        }
    }, [deviceOrientation, deviceType, showScreen]);


    /*
    * Get the call handles
    */
    const setCallHandles = useCallback((call?: Call) => {
        videoProperties.callHandle = call;
        audioInProperties.callHandle = call;
        audioOutProperties.callHandle = call;
    }, [videoProperties, audioInProperties, audioOutProperties]);


    const hangUpCall = useCallback((call: Call) => {
        console.log('hangUpCall');
        call?.hangUp().then(() => {
            console.log('Call ended');
            setCallHandles(undefined);
        });
    }, [setCallHandles]);

    const meetingEnded = useCallback((call: Call) => {
        hangUpCall(call);
        console.log('meetingEnded()');
        console.log('startTime:', startTime);
        if (startTime) {
            const endTime = Date.now();
            console.log('Meeting ended time:', endTime);
            const durationInSeconds = (endTime - startTime) / 1000;
            trackEvent('appointment_details', {
                'video_or_audio': videoWasUsed ? 'Video and Audio' : 'Audio only',
                'duration': durationInSeconds,
                'event_type': theResult?.result.isReadinessCheck ? 'Technical Support Appointment' : 'Telehealth Appointment'
            });
            setStartTime(undefined);
        }
        setShowScreen(ROUTES.ended);
    }, [hangUpCall, setShowScreen, startTime, videoWasUsed, theResult]);


    const onLeaveAppointmentClick = () => {
        trackEvent('Ready Screen - Leave Appointment Click');
        setShowLeaveAppointmentAlert(true);
    }

    const onHangupClick = () => {
        trackEvent('Meeting Screen - Hangup Click');
        setShowLeaveAppointmentAlert(true);
    }

    const onResumeAppointmentClickFromAlert = () => {
        trackEvent('Leave Alert - Resume Appointment Click');
        setShowLeaveAppointmentAlert(false);
    }
    const onLeaveAppointmentClickFromAlert = () => {
        trackEvent('Leave Alert - Leave Appointment Click');
        setShowLeaveAppointmentAlert(false);
        if (videoProperties.callHandle) {
            meetingEnded(videoProperties.callHandle);
        }
    }

    const toggleCamera = useCallback(() => {
        const nextCameraOn = !cameraOn;
        if (nextCameraOn) {
            setVideoWasUsed(true);
            handleLocalVideo(videoProperties, true, localVideoRef.current);
            trackEvent("Meeting Screen - Camera on");
        } else {
            handleLocalVideo(videoProperties, false, localVideoRef.current);
            trackEvent("Meeting Screen - Camera off");
        }
        setCameraOn(!cameraOn)
    }, [cameraOn, videoProperties, localVideoRef, setCameraOn]);

    /*
    * Set the main video window
    */
    const makeLocalVideoMain = useCallback(() => {
        if (callAgent?.calls.length) {
            setLocalVideoIsMain(true);
            // remoteVideoElement.classList.remove("main-video");
            // remoteVideoElement.classList.remove("video-off");
            // localVideoElement.classList.remove("minimal-video");
            // localVideoElement.classList.add("main-video");
        }
    }, [setLocalVideoIsMain, callAgent]);

    const joinMeeting = useCallback(() => {
        if (!callAgent) return;
        console.log("Joining meeting...");

        // this is the actual meeting url for MS Teams
        const meetingUrl = theResult?.result.meetingJoinWebUrl || '';

        //const call = callAgent.join({ meetingLink: meetingUrl }, ctBarVideoButton.classList.contains(buttonActive) ? videoProperties.callOptions : {});
        const _call = callAgent.join({ meetingLink: meetingUrl }, videoProperties.callOptions);
        setCallHandles(_call);

        _call.on('stateChanged', () => {
            if (_call != null) {
                console.log('Call state changed', _call.state);
                switch (_call.state) {
                    case "Connected": 
                        toggleCamera();
                        setShowScreen(ROUTES.meeting);
                        // make the changes here to adjust video for mobile devices
                        setupMobileDeviceVideo();
                        break;

                    case "InLobby":
                    case "Disconnecting":
                    case "Disconnected":
                        break;

                    default:
                        makeLocalVideoMain();
                }
            }

            if (!audioInProperties.isOn) muteMicrophone(audioInProperties);
        });

        _call.on('remoteParticipantsUpdated', (e) => {
            if (_call.remoteParticipants.length === 0) {
                console.log('All participants left');
                meetingEnded(_call);
            }
        });

        _call.on('localVideoStreamsUpdated', (e) => {
            if (_call) {
                console.log('localVideoStreamsUpdated', {
                    added: simplifyLocalVideoStreams(e.added),
                    removed: simplifyLocalVideoStreams(e.removed)
                });
            }
        });

        // call.api(Features.Recording).on('isRecordingActiveChanged', () => {
        //     if (call.api(Features.Recording).isRecordingActive) {
        //         //recordingStateElement.innerText = "This call is being recorded";
        //     } else {
        //         //recordingStateElement.innerText = "";
        //     }
        // });

        subscribeToRemoteVideo(videoProperties, remoteVideoRef.current);
        //handleLocalVideo(videoProperties, videoProperties.isOn, ctBarVideoButton);
        handleLocalVideo(videoProperties, false, localVideoRef.current);

    }, [callAgent, theResult?.result.meetingJoinWebUrl, videoProperties, setCallHandles, audioInProperties, makeLocalVideoMain, toggleCamera, setShowScreen, setupMobileDeviceVideo, meetingEnded]); 

    useEffect(() => {
        if (patientName) {
            setAllSetLabel("You're all set,")
        } else {
            setAllSetLabel("You're all set")
        }
    }, [patientName]);

    useEffect(() => {
        let interval: any;
        if (showScreen === ROUTES.meeting) {
            const _startTime = Date.now();
            console.log('Meeting start time:', _startTime);
            setStartTime(_startTime);
            interval = setInterval(() => {
                console.log('Meeting still active');
            }, 1000 * 60);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, [showScreen])

    useEffect(() => {
        if (audioInProperties && audioOutProperties && videoProperties) {
            if (joinMeetingTimeout.current) {
                clearTimeout(joinMeetingTimeout.current);
            }
            joinMeetingTimeout.current = setTimeout(() => {
                if (!alreadyJoinedMeeting) {
                    setAlreadyJoinedMeeting(true);
                    handleLocalVideo(videoProperties, false, localVideoRef.current);
                    handleMics(audioInProperties);
                    handleSpeakers(audioOutProperties);

                    joinMeeting();
                }
            }, 500);
        }
        return () => {
            // reset body color
            document.body.style.backgroundColor = "#F7F7F7";
        }
    }, [audioInProperties, audioOutProperties, videoProperties, joinMeeting, alreadyJoinedMeeting]);

    const toggleMicrophone = useCallback(() => {
        const nextMicrophoneOn = !microphoneOn;
        if (nextMicrophoneOn) {
            unmuteMicrophone(audioInProperties);
            trackEvent("Meeting Screen - Microphone on");
        } else {
            muteMicrophone(audioInProperties);
            trackEvent("Meeting Screen - Microphone off");
        }
        setMicrophoneOn(nextMicrophoneOn);
    }, [microphoneOn, setMicrophoneOn, audioInProperties]);

    let meetingClassName = '';
    if (context.deviceType[0] === 'mobile' && context.deviceOrientation[0] === 'landscape') {
        meetingClassName = style.landscape;
    }
    return (
        <Page>
            <div className={style.allSetToJoinContainer + ` ${showScreen === ROUTES.ready ? '' : 'hidden'}`}>
                <h1 className={style.allSetToJoinMessage}>{allSetLabel} {patientName}</h1>
                <div>
                    <div className="checkDevicesSeparator"></div>
                    <div className="allSetToJoinMicrophoneContainer">
                        <div className="checkedDeviceImage">
                            <img src={process.env.PUBLIC_URL + '/assets/img/checked.png'} alt="" />
                        </div>
                        <div className="allSetToJoinMicrophoneImage">
                            <img src={process.env.PUBLIC_URL + '/assets/img/microphone.png'} alt="" />
                        </div>
                        <div className="allSetToJoinMicrophone">
                            <p>Microphone</p>
                        </div>
                    </div>
                    <div className="checkDevicesSeparator"></div>
                    <div className="allSetToJoinCameraContainer">
                        <div className="checkedDeviceImage">
                            <img src={process.env.PUBLIC_URL + '/assets/img/checked.png'} alt="" />
                        </div>
                        <div className="allSetToJoinCameraImage">
                            <img src={process.env.PUBLIC_URL + '/assets/img/camera.png'} alt="" />
                        </div>
                        <div className="allSetToJoinCamera">
                            <p>Camera</p>
                        </div>
                    </div>
                    <div className="checkDevicesSeparator"></div>
                    {/*<SettingsMenu audioInProps={audioInProperties} audioOutProps={audioOutProperties} videoProps={videoProperties}></SettingsMenu>*/}
                </div>
                <div className="allSetToJoinWaitDoctorMessage">
                    <p>Your doctor will be joining you shortly...</p>
                </div>
                <div className="allSetToJoinControlsContainer">
                    <div className={style.link} onClick={onLeaveAppointmentClick}>Leave appointment</div>
                </div>
            </div>


            <div className={meetingClassName + ' ' + style.verticalMeetingContainer + ` ${showScreen === ROUTES.meeting ? '' : 'hidden'}`}>
                <div className={style.meetingContainer}>
                    <div ref={remoteVideoRef} className={style.remoteVideo}></div>
                    <div ref={localVideoRef} className={style.localVideo + ` ${localVideoIsMain ? 'main-video' : ''} ${cameraOn ? style.videoOff : ''}`}></div>

                    <div className={style.controlButtonsContainer}>
                        <div className={style.joinControlButtonsContainer}>
                            <div>
                                <button type="button" className={style.cbButton} onClick={toggleCamera}>
                                    <span role="img" aria-hidden="true" className="ui-icon">
                                        <img src={cameraOn ? IMAGES.videoOn : IMAGES.videoOff} alt={cameraOn ? 'Camera is on' : 'Camera is off'} />
                                    </span>
                                </button>
                            </div>
                            <div>
                                <button type="button" className={style.cbButton} onClick={toggleMicrophone}>
                                    <span role="img" aria-hidden="true" className="ui-icon">
                                        <img src={microphoneOn ? IMAGES.micOn : IMAGES.micOff} alt={microphoneOn ? 'Microphone is On' : 'Microphone is off'} />
                                    </span>
                                </button>
                            </div>
                            <div>
                                <button type="button" className={style.cbButton + ' ' + style.callButton} onClick={onHangupClick}>
                                    <span role="img" aria-hidden="true" className="ui-icon">
                                        <img src={IMAGES.hangup} alt={'Hang up'} />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<SettingsMenu audioInProps={audioInProperties} audioOutProps={audioOutProperties} videoProps={videoProperties}></SettingsMenu>*/}
            </div>
            <Alert
                showing={showLeaveAppointmentAlert}
                text={'Your Telehealth meeting is in progress. Are you sure you want to leave?'}
                buttons={[
                    { text: 'Resume Appointment', onClick: onResumeAppointmentClickFromAlert },
                    { text: 'Leave Appointment', isNegative: true, onClick: onLeaveAppointmentClickFromAlert }
                ]}
            ></Alert>
        </Page>
    );
};