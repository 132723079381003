import {
    Call,
    CollectionUpdatedEvent,
    DeviceManager,
    LocalVideoStream,
    RemoteParticipant,
    RemoteVideoStream,
    VideoDeviceInfo,
    VideoStreamRenderer
} from "@azure/communication-calling";
import { logError } from "../shared/logging";
import { simplifyRemoteVideoStream, simplifyRemoteVideoStreams } from "../shared/utils";

export class VideoProperties {
    isOn: boolean;
    deviceManager?: DeviceManager;
    callHandle?: Call;
    callOptions: any;
    availableCams?: VideoDeviceInfo[];
    selectedCam?: VideoDeviceInfo;
    localVideoStream?: LocalVideoStream;
    localVideoElement?: HTMLElement;
    rendererLocal?: VideoStreamRenderer;
    rendererRemote?: VideoStreamRenderer;


    constructor(isOn: boolean = false, deviceManager?: DeviceManager, callHandle?: Call, callOptions?: any, availableCams?: VideoDeviceInfo[], selectedCam?: VideoDeviceInfo, localVideoStream?: LocalVideoStream, localVideoElement?: HTMLElement, rendererLocal?: VideoStreamRenderer, rendererRemote?: VideoStreamRenderer) {
        this.isOn = isOn;
        this.deviceManager = deviceManager;
        this.callHandle = callHandle;
        this.callOptions = callOptions;
        this.availableCams = availableCams;
        this.selectedCam = selectedCam;
        this.localVideoStream = localVideoStream;
        this.localVideoElement = localVideoElement;
        this.rendererLocal = rendererLocal;
        this.rendererRemote = rendererRemote;
    }
}

export function handleLocalVideo(props: VideoProperties, activateVideo: boolean, localVideoRef?: HTMLElement | null) {
    init(activateVideo, props).then(async () => {
        if (props.selectedCam) {
            await handleLocalRender(props, localVideoRef);
            await handleLocalBroadcast(props);
        } else {
            logError("No video device selected");
            //alert("no video device selected");
            // if (toggleVideoButton) toggleVideoButton.disabled = true;
        }
    });
}

export function switchCameraSource(props: VideoProperties, cameraName?: string) {
    if (!props.isOn) props.isOn = false;
    if (cameraName) {
        props.availableCams?.forEach((cam) => {
            if (cam.name === cameraName) {
                stopLocalVideo(props).then(() => {
                    props.selectedCam = cam;
                    handleLocalVideo(props, props.isOn, null);
                });
            }
        });
    } else if (props.availableCams && props.availableCams.length > 0) {
        stopLocalVideo(props).then(() => {
            props.selectedCam = props.availableCams && props.availableCams[0];
            handleLocalVideo(props, props.isOn, null);
        });
    } else {
        logError("Invalid camera", cameraName);
        alert("Invalid Camera: " + cameraName);
    }
}

export function subscribeToRemoteVideo(props: VideoProperties, remoteVideoRef?: HTMLElement | null) {
    props.callHandle?.on('remoteParticipantsUpdated', (e) => {
        e.added.forEach((p) => {
            subscribeToParticipantVideoStreams(p, props, remoteVideoRef);
        })
    });
    props.callHandle?.remoteParticipants.forEach((p) => {
        subscribeToParticipantVideoStreams(p, props, remoteVideoRef);
    })
}

function subscribeToParticipantVideoStreams(remoteParticipant: RemoteParticipant, props: VideoProperties, remoteVideoRef?: HTMLElement | null) {
    remoteParticipant.on('videoStreamsUpdated', (e) => {
        console.log('remoteVideoStreamsUpdated', {
            added: simplifyRemoteVideoStreams(e.added),
            remove: simplifyRemoteVideoStreams(e.removed)
        });
        e.added.slice().reverse().forEach((v) => {
            handleVideoStream(v, props, remoteVideoRef);
        })
    });
    remoteParticipant.videoStreams.forEach((v) => {
        handleVideoStream(v, props, remoteVideoRef);
    });
}

function handleVideoStream(remoteVideoStream: RemoteVideoStream, props: VideoProperties, remoteVideoRef?: HTMLElement | null) {
    remoteVideoStream.on('isAvailableChanged', async () => {
        console.log('remoteVideoStreamAvailabilityChanged', simplifyRemoteVideoStream(remoteVideoStream));
        if (remoteVideoStream.isAvailable) {
            remoteVideoView(remoteVideoStream, props, remoteVideoRef);
        } else {
            props.rendererRemote?.dispose();
        }
    });
    if (remoteVideoStream.isAvailable) {
        remoteVideoView(remoteVideoStream, props, remoteVideoRef);
    }
}

async function remoteVideoView(remoteVideoStream: RemoteVideoStream, props: VideoProperties, remoteVideoRef?: HTMLElement | null) {
    props.rendererRemote = new VideoStreamRenderer(remoteVideoStream);
    const view = await props.rendererRemote.createView();

    const remoteVideoContainer = remoteVideoRef;
    if (remoteVideoContainer) {
        if (remoteVideoContainer.children.length <= 2) {
            remoteVideoContainer.appendChild(view.target);
        } else {
            console.warn('Too many video streams connected', remoteVideoContainer.children.length);
            //alert("Maximum number of video streams reached. Additional callers will be audio only");
        } 

        if (!remoteVideoContainer.classList.contains("remote-active"))
            remoteVideoContainer.classList.add("remote-active");
        if (remoteVideoContainer.classList.contains("video-off"))
            remoteVideoContainer.classList.remove("video-off");
    }
}

async function init(activateVideo: boolean, props: VideoProperties) {
    try {
        if (!props.deviceManager) {
            logError("Device Manager not initialized in Video");
        }
        if (!props.availableCams) props.availableCams = await props.deviceManager?.getCameras();
        if (!props.selectedCam && props.availableCams && props.availableCams.length > 0)
            props.selectedCam = props.availableCams[0];

        // if (toggleVideoButton && props.selectedCam) {
        //     toggleVideoButton.disabled = false;
        //     toggleVideoButton.classList.remove("call-disabled");
        // }

        if (activateVideo != null) { props.isOn = activateVideo; }
        else { props.isOn = !props.isOn; }
    } catch (e) {
        console.log(e);
        props.availableCams = [];
        props.isOn = false;
    }
}

async function handleLocalRender(props: VideoProperties, localVideoRef?: HTMLElement | null) {
    console.log('Selected Camera', props.selectedCam?.name);
    if (!props.localVideoStream && props.selectedCam) props.localVideoStream = new LocalVideoStream(props.selectedCam);
    if (!props.rendererLocal && props.isOn && props.localVideoStream) {
        props.rendererLocal = new VideoStreamRenderer(props.localVideoStream);
        const view = await props.rendererLocal.createView();
        while (localVideoRef?.firstChild) {
            localVideoRef?.firstChild?.remove();
        }
        localVideoRef?.appendChild(view.target);
        props.localVideoElement = view.target;
    } else if (!props.isOn) {
        await stopLocalVideo(props);
    }
}

async function handleLocalBroadcast(props: VideoProperties) {
    if (!props.callHandle && props.isOn) {
        props.callOptions = { videoOptions: { localVideoStreams: [props.localVideoStream] } };;
    } else if (props.isOn && props.localVideoStream) {
        await props.callHandle?.startVideo(props.localVideoStream);
    }
}

async function stopLocalVideo(props: VideoProperties) {
    if (props.callHandle && props.localVideoStream) try { await props.callHandle.stopVideo(props.localVideoStream); } catch { }

    if (props.rendererLocal) {
        props.rendererLocal.dispose();
        props.rendererLocal = undefined;
    }
}
