import React, { useContext, useEffect } from 'react'
import { fetchMore } from '../../API/fetchMore';
import { TelehealthMeetingResponse } from '../../API/models';
import { CONFIG } from '../../config';
import { MEETING_RESULT, ROUTES } from '../../constants';
import { AppContext } from '../../shared/AppContextProvider/AppContextProvider';
import { logError, logger, logInfo } from '../../shared/logging';
import { Page } from '../../shared/Page/Page';
import { trackEvent } from '../../shared/tracking';
import { checkDateStatus, iOSorAndroid, iOSversion, parseQuery } from '../../shared/utils';
import style from './Init.module.scss';
import { v4 as uuidv4 } from 'uuid';

let telehealthMeetingResponse: any;
try {
    const mockData = require('../../API/mockData');
    telehealthMeetingResponse = mockData?.telehealthMeetingResponse || {};
} catch (e) {
    telehealthMeetingResponse = {}
}

export const Init = () => {

    const context = useContext(AppContext);
    const [, setTheResult] = context.theResult;
    const [, setShowScreen] = context.showScreen;

    useEffect(() => {
        const qsObj = parseQuery();
        let deviceId = localStorage.getItem('deviceId');
        if (!deviceId) {
            deviceId = uuidv4();
            localStorage.setItem('deviceId', deviceId);
        }
        logger.addContext("deviceId", deviceId);
        logger.addContext("dateTime", new Date().toString());
        logger.addContext("meetingId", qsObj.meetingId);
        fetchMore(CONFIG.requestPath, new Request(CONFIG.requestHost + CONFIG.requestPath + qsObj.meetingId, {
            method: 'GET',
        }), undefined, telehealthMeetingResponse).then((responseObj) => {
            if (!responseObj || !responseObj.ok) {
                logError('Meeting API Error - No ResponseObj or not ok', responseObj);
                setShowScreen(ROUTES.error);
                return;
            }

            responseObj.text().then(responseText => {
                if (!responseText) {
                    logError('Meeting API Error - No Response');
                    setShowScreen(ROUTES.error);
                    return;
                }
                const response: TelehealthMeetingResponse = JSON.parse(responseText);
                if (response.result === null || response.message || response.result.id === null) {
                    logError('Meeting API Error', response);
                    setShowScreen(ROUTES.error);
                    return;
                }
                logger.addContext("isReadinessCheck", !!response.result.isReadinessCheck);
                logger.addContext("providerName", response.result.appointmentProviderName || '');
                logger.addContext("appointmentDateTime", response.result.appointmentDateTime || '');

                trackEvent(response.result.isReadinessCheck ? 'Event Type - Readiness Check' : 'Event Type - Telehealth Appointment');
                logInfo('Meeting API Response', response);
                setTheResult(response);
                var meetingResult = checkDateStatus(response.result.appointmentDateTime, response.result.appointmentDuration);

                if (meetingResult === MEETING_RESULT.MEETING_COMPLETED) {
                    console.log("Meeting completed");
                    setShowScreen(ROUTES.ended)
                } else if (meetingResult === MEETING_RESULT.MEETING_ONGOING) {
                    console.log("Meeting is ongoing");
                    // check first if the app is running in iOS and which version
                    var platformVersion = iOSversion();
                    console.log("iOS version is: " + platformVersion);

                    // evaluate if iOS 15.1
                    //if (iOSorAndroid() && ( platformVersion[0] === 15 && platformVersion[1] === 1)) {
                    if (iOSorAndroid() && platformVersion && platformVersion[0] === 15 && platformVersion[1] === 1) {
                        // this is iOS 15.1, video will not work, display a message for unsupported os version
                        setShowScreen(ROUTES.unsupportedIos);
                    } else {
                        setShowScreen(ROUTES.welcome);
                    }

                } else if (meetingResult === MEETING_RESULT.MEETING_UPCOMING) {
                    console.log("Meeting is upcoming");
                    setShowScreen(ROUTES.future);
                }
            }).catch(e => {
                logError('Meeting API Response Exception', e);
                console.error(e);
            });


        }).catch(err => {
            console.error('Meeting API exception', err);
            setShowScreen(ROUTES.error);
        });
    }, [setShowScreen, setTheResult]);
    return (
        <Page>
            <div className={style.processingContainer}>
                <h1>Retrieving your telehealth appointment details...</h1>
                <div className={style.progressContainer}>
                    <div className={style.progressIcon1}>
                    </div>
                    <div className={style.progressIcon2}>
                    </div>
                    <div className={style.progressIcon3}>
                    </div>
                    <div className={style.progressIcon4}>
                    </div>
                    <div className={style.progressIcon5}>
                    </div>
                    <div className={style.progressIcon6}>
                    </div>
                </div>
            </div>
        </Page>
    );
};