import React from 'react'
import style from './Spinner.module.scss';

interface SpinnerProps {
    enabled: boolean;
}

export const Spinner = (props: SpinnerProps) => {
    if (props.enabled) {
        return (
            <div className={style.container}>
                <img className={"spinnerPng"} alt="Loading" />
            </div>
        );
    } else {
        return <></>;
    }
};