import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { CONFIG } from "../config";
import { logger } from "./logging";

let app: FirebaseApp;
let analytics: Analytics;

let lastPagePath: string | undefined;
let lastPageTime: number | undefined;

export const initFirebase = () => {
    app = initializeApp(CONFIG.firebaseConfig);
    analytics = getAnalytics(app);
}

export const trackEvent = (event: string, params?: any) => {
    console.log('Tracking Event', {
        ...(params || {}),
        event,
    });
    if (analytics) {
        logEvent(analytics, event, params);
    }
}

export const trackPage = (path: string) => {
    if (analytics) {
        trackPageTime();
        logEvent(analytics, 'page_view', {
            'page_path': path,
        });
    }
    logger.addContext("currentPage", path);
    console.log('Tracking Page view', { page_path: path });
    lastPagePath = path;
    lastPageTime = Date.now();
}

export const trackPageTime = () => {
    if (analytics && lastPagePath && lastPageTime) {
        const time = (Date.now() - lastPageTime) / 1000;
        trackEvent("Page time", {
            "page_path": lastPagePath,
            time
        });
        lastPagePath = undefined;
        lastPageTime = undefined;
    }
}