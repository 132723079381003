import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../AppContextProvider/AppContextProvider';
import { Card } from '../Card/Card';
import style from './MeetingInfoCard.module.scss';

interface MeetingInfoCardProps {

}

export const MeetingInfoCard = (props: MeetingInfoCardProps) => {
    const context = useContext(AppContext);
    const [theResult] = context?.theResult;
    const [appointmentDateOnly, setAppointmentDateOnly] = useState<string>();
    const [appointmentDayLong, setAppointmentDayLong] = useState<string>();
    const [appointmentTimeTimezone, setAppointmentTimeTimezone] = useState<string>();
    const [providerName, setProviderName] = useState<string>();
    const [isReadinessCheck, setIsReadinessCheck] = useState<boolean>();

    useEffect(() => {
        const meetingDetail = theResult?.result;
        if (meetingDetail) {
            const meetingStart = new Date(meetingDetail.appointmentDateTime);
            setAppointmentDateOnly(meetingStart.toLocaleString([], { month: 'short', day: 'numeric' }));
            setAppointmentDayLong(meetingStart.toLocaleString([], { weekday: 'long' }));
            setAppointmentTimeTimezone(meetingStart.toLocaleString([], { hour: 'numeric', minute: "2-digit", hour12: true, timeZoneName: 'short' }));
            setProviderName(meetingDetail.appointmentProviderName || undefined);
            setIsReadinessCheck(meetingDetail.isReadinessCheck);
        } else {
            setAppointmentDateOnly(undefined);
            setAppointmentDayLong(undefined);
            setAppointmentTimeTimezone(undefined);
            setProviderName(undefined);
            setIsReadinessCheck(false);
        }
    }, [theResult]);

    return (
        <Card>
            <div className={style.title}>{isReadinessCheck ? 'Technical Support Appointment' : 'Telehealth Appointment'}</div>
            <div className={style.text}>
                {providerName && <>with {providerName}<br /></>}
                {appointmentDateOnly}, {appointmentDayLong} - {appointmentTimeTimezone}
            </div>
        </Card>
    );
};