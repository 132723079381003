interface CSSClasses {
    [key: string]: boolean | string,
}
export const css = (classNames: CSSClasses | (string | undefined)[]) => {
    let classString = '';
    if (classNames.length) {
        const a: string[] = classNames as string[];
        classString = a.join(' ');
    } else {
        const o: CSSClasses = classNames as CSSClasses;

        Object.keys(o).forEach(className => {
            if (o[className]) {
                classString += className + ' ';
            }
        });

    }
    return classString;
}