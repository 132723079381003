import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../shared/AppContextProvider/AppContextProvider';
import { Page } from '../../shared/Page/Page';
import style from './Ended.module.scss';

export const Ended = () => {
    const context = useContext(AppContext);
    const [theResult] = context.theResult;
    const [appointmentDateOnly, setAppointmentDateOnly] = useState<string>();
    const [appointmentDayLong, setAppointmentDayLong] = useState<string>();
    const [appointmentTimeTimezone, setAppointmentTimeTimezone] = useState<string>();
    const [providerName, setProviderName] = useState<string>();

    useEffect(() => {
        const meetingDetail = theResult?.result;
        if (meetingDetail) {
            const meetingStart = new Date(meetingDetail.appointmentDateTime);
            setAppointmentDateOnly(meetingStart.toLocaleString([], { month: 'long', day: 'numeric', year: 'numeric' }));
            setAppointmentDayLong(meetingStart.toLocaleString([], { weekday: 'long' }));
            setAppointmentTimeTimezone(meetingStart.toLocaleString([], { hour: 'numeric', minute: "2-digit", hour12: true, timeZoneName: 'short' }));
            setProviderName(meetingDetail.appointmentProviderName || undefined);
        } else {
            setAppointmentDateOnly(undefined);
            setAppointmentDayLong(undefined);
            setAppointmentTimeTimezone(undefined);
            setProviderName(undefined);
        }
    }, [theResult]);

    return (
        <Page>
            <div className={style.meetingEndedContainer}>
                <h2>Your telehealth appointment has ended.</h2>
                <div className="meetingDetails">
                    <p>{appointmentDateOnly}</p>
                    <p>{appointmentDayLong}{appointmentTimeTimezone && ', '}{appointmentTimeTimezone}</p>
                    {providerName && <p>with {providerName}</p>}
                </div>
            </div>
        </Page>
    );
};